.App {
  text-align: center;
  font-family: 'AvenirNext';
}

.App-header {
  background-color: #222222ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/* -------------------------------------------------------------- 
 * ## text rotator
 * -------------------------------------------------------------- */

/*Sentence*/
.sentence {
  color: #E8318A;
  font-size: 45px;
  width: 70%;
  position: relative;
  float: left;
}

.sentence:nth-child(2) {
  width: 30%;
  text-align: left;
  text-indent: 1px;
}

/*Wrapper*/
.wrapper {
  margin: 100px auto;
  position: relative;
  width: 50%;
}

/*Vertical Sliding*/
.slidingVertical {
  display: contents;
  float: left;
}

.slidingVertical span {
  animation: topToBottom 7.5s linear infinite 0s;
  -ms-animation: topToBottom 7.5s linear infinite 0s;
  -webkit-animation: topToBottom 7.5s linear infinite 0s;
  animation-timing-function:ease;
  color: #809cad;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  color: white;
  right: 0;
}

.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
  animation-timing-function: ease;
}

.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
  animation-timing-function: ease;
}


/*topToBottom Animation*/
@-moz-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -moz-transform: translateY(-100px);
  }

  10% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -moz-transform: translateY(100px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    transform: translateY(-50px);
  }

  10% {
    opacity: 1;
    transform: translateY(0px);
  }

  25% {
    opacity: 1;
    transform: translateY(0px);
  }

  30% {
    opacity: 0;
    transform: translateY(50px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  {
  .sentence {
    font-size: 60px;
  }
  .social-icons a {
    padding: 15px;
  }
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }

svg {
  width: 35px;
}

path {
  transition: .5s linear;
  cursor: pointer;
  fill: white;
}

svg:hover path {
  animation: dash 2s linear alternate infinite;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke-width: 20;
  fill: white;
  stroke: #E8318A;
}

@keyframes dash {
  from {
    stroke-dashoffset: 822;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.social-icons a {
  cursor: pointer;
  padding: 5px;
}